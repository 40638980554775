<template id="tmplOrderTenders">

  <div id="orderTendersMainDiv">

    <section class="container-fluid vld-parent" id="orderTendersMainSection">
      <vue-snotify id="orderTendersSnotify"></vue-snotify>

      <loading
        id="orderTendersLoading"
        :active.sync="isLoading"
        :is-full-page="true"
        color="#114C8E"

      ></loading>
      <b-row id="ordersTitleQuickSearchRow">
        <b-col lg="8" md="8" sm="12" id="ordersTitleCol">
          <h2 class="text-primary" id="ordersTitle">
            <label id="lblOrders">Orders</label>
            <i id="icnOrderTitle" class="fa fa-question-circle text-primary bottom-align-text ml-2"
               style="font-size:large; vertical-align: top"  
               title="Order List"></i>
            <b-popover target="icnOrderTitle"
                       :show.sync="showTooltip"
                       @shown="hideTooltipLater"
                       triggers="hover"
                       placement="right"
                       title="Order List">
                       <span v-html="AddOrderScreenPopoverText" />
            </b-popover>
          </h2>
 
        </b-col>
        <b-col id="quickSearchCol" lg="4" md="4" sm="12" >
          <b-row id="quickSearchRow">
            <b-input-group id="quickSearchInputGroup" class="has-feedback has-clear">
              <b-form-input
                id="quickSearchInput"
                v-model="filterObject.orderReferenceNumber"
                @keyup.enter="getOrderTenders(true)"
                placeholder="Quick search by Order/Reference #"

              />

              <b-input-group-append id="inptGrpApdQuickSearch">
                <b-button
                  id="btnQuickSearch"
                  v-if="filterObject.orderReferenceNumber"
                  @click="clearQuickSearch"
                  variant="danger"
                >
                  <i id="txtItlQuickSearch" class="fas fa-times" ></i>
                </b-button>
              </b-input-group-append>

              <b-input-group-append id="inpGrpApdGetOrders">
                <b-button id="btnGetOrders" @click="getOrderTenders(true)" variant="primary">
                  <i id="txtItlGetOrders" class="fa fa-search"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-row>
        </b-col>
      </b-row>

			<div class="text-right" id="filterButtonDiv">
				<b-button
					@click="showFiltersSlideOut()"
					:variant="filterButtonClass"
					class="my-2"
					id="btnFilter"
					>{{ filterButtonText }}</b-button>
			</div>

      <b-row id="ordersTableRow">
        <b-card
         
          :class="{
            'webview-card': windowWidth >= 768,
            'mobile-card': windowWidth < 768
          }"

        >
          <b-alert
            id="altTooManyOrdersWarning"
            variant="warning"
            :show="$store.state.order.orders.length >= orderResultsMessageThreshold"
            dismissible

          >
            More than {{ orderResultsMessageThreshold }} orders have been
            returned. Please use/refine the advanced filters to retrieve a
            smaller dataset.
          </b-alert>

          <b-row class="mb-sm-4" id="ordersTableButtonRow">
            <b-col id="ordersTableButtonsLeft">
              <b-button v-if="canCreateAdHocOrders2"
								variant="primary"
								class="mr-3 mb-2 mb-sm-0"
								@click="NavigateToNewOrderPage()"
								id="btnCreateNewOrder"
								href="new-order-tender">
                <i class="fa fa-plus mr-1" id="txtItlCreateNewOrder"></i>Create New Order
							</b-button>

              <b-button variant="light"
								@click="GetImagesForSelectedOrders()"
								class="mr-1 mr-3-md"
								:disabled="this.$store.state.order.selectedOrders.length === 0"
								v-if="allowBulkImageExports === 'true'"
								id="btnGetImagesForSelectedOrders">

                <i class="fa fa-download mr-1" id="txtItlGetImagesForSelectedOrders"></i>
                <span class="d-inline d-sm-none" id="getImagesForSelectedOrdersImagesSpan">Images</span>
                <span class="d-none d-sm-inline" id="getImagesForSelectedOrdersGetImagesSpan">Get Images for Selected Orders</span>
                ({{ this.$store.state.order.selectedOrders.length }})
              </b-button>

              <b-button variant="light"
								@click="onImageDownloadButtonClick()"
								class="mr-1 mr-3-md"
								:disabled="this.$store.state.order.selectedOrders.length === 0"
								id="btnExportSelectedOrdersn">

                <i class="fa fa-download mr-1" id="txtItlExportSelectedOrders"></i>
                <span class="d-inline d-sm-none" id="exportSelectedOrdersExportSpan">Export</span>
                <span class="d-none d-sm-inline" id="exportSelectedOrdersExportSelectedSpan">Export Selected Orders</span>
                ({{ this.$store.state.order.selectedOrders.length }})
              </b-button>

              <b-button id="btnClearAllSelected" variant="outline-danger"
									@click="clearAllSelected()"
									v-if="this.$store.state.order.selectedOrders.length > 0"
									>

                <i class="fa fa-close mr-1 mr-3-md" id="txtItlclearAllSelected"></i>
                <span class="d-none d-sm-inline" id="clearAllSelectedSpan">Clear Selected Orders</span>
              </b-button>
            </b-col>

            <b-col md="6" class="my-1" id="colOrdersTableButtonsRight">
              <b-form inline class="float-md-right" id="ordersTableButtonsRightForm">
                <label class="mr-sm-2 col-form-label-sm" for="perPageSelect" id="ordersTableButtonsRightLabel">Per page:</label>
                <b-form-select v-model="perPage"
										id="perPageSelect"
										size="sm"
										@change=getOrderTenders(true)
										:options="pageOptions">
								</b-form-select>
              </b-form>

            </b-col>
          </b-row>

          <b-row id="rowOrdersTableHeader"
            v-if="windowWidth >= 768 && total > 0"
            class="card-table-header-tr mx-0"
          >
            <b-col class="card-table-header-td" lg="1" md="2" id="ordersTableOrderNumberCol">
              <b-form-checkbox
								data-testid="chkBxOrdersTableSelectAllOrdersId"
                id="chkBxOrdersTableSelectAllOrders"
                @change="selectAllOrders($event)"
                style="display:inline"
                v-model="selectAll"
              >
              </b-form-checkbox>

              <span @click="setSort('ORDERNUMBER')" style="cursor:pointer" id="ordersTableOrderNumberSpan">
                Order #
              </span>

              <span id="ordersTableOrderNumberIconSpan">
                <i id="txtItlOrdersTableOrderNumber"
                  v-if="sortField === 'ORDERNUMBER'"
                  :class="[
                    sortDirection === '-' ? 'fa-caret-down' : 'fa-caret-up',
                    'fa pl-2'
                  ]"
                ></i>
                <i id="txtItlOrdersTableOrderNumberBlank" v-else class="fas fa-sort opacity-2" ></i>
              </span>
            </b-col>

						<!-- <b-col class="card-table-header-td" lg="1" md="2" id="ordersTableBOLsCol">BOLs</b-col> -->
            <b-col class="card-table-header-td" lg="1" md="2" :id="refNumTypesCol1 ? `${refNumTypesCol1.abbr}-${refNumTypesCol1.colNumber}` : 'ordersTableBOLsCol'">{{ refNumTypesCol1 ? refNumTypesCol1.name : 'BOLs' }}</b-col>

						<!-- <b-col class="card-table-header-td" lg="1" md="2" id="ordersTable3POCol">#3PO</b-col> -->
						<b-col class="card-table-header-td" lg="1" md="2" :id="refNumTypesCol2 ? `${refNumTypesCol2.abbr}-${refNumTypesCol2.colNumber}` : 'ordersTable3POCol'">{{ refNumTypesCol2 ? refNumTypesCol2.name : '#3PO' }}</b-col>

            <!-- <b-col class="card-table-header-td text-center" lg="1" md="2" id="ordersTableTenderNumberCol">Tender #</b-col> -->
            <b-col class="card-table-header-td text-center" lg="1" md="2" :id="refNumTypesCol3 ? `${refNumTypesCol3.abbr}-${refNumTypesCol3.colNumber}` : 'ordersTableTenderNumberCol'">{{ refNumTypesCol3 ? refNumTypesCol3.name : 'Tender #' }}</b-col> 

            <b-col class="card-table-header-td text-center" lg="2" md="1">Initial Load Window</b-col>

            <b-col class="card-table-header-td text-center" lg="2" md="2" id="ordersTableDeliveryDateCol">
              <span @click="setSort('DELIVERYDATE')" style="cursor:pointer" id="ordersTableDeliveryDateSpan">
               Scheduled Delivery Date
              </span>
              <span id="ordersTableDeliveryDateIconSpan">
                <i id="txtItlOrdersTableDeliveryDate"
                  v-if="sortField === 'DELIVERYDATE'"
                  :class="[
                    sortDirection === '-' ? 'fa-caret-down' : 'fa-caret-up',
                    'fa pl-2'
                  ]"

                ></i>
                <i id="txtItlOrdersTableDeliveryDateBlank" v-else class="fas fa-sort opacity-2"></i>
              </span>
            </b-col>

            <b-col class="card-table-header-td text-center" lg="2" md="2" id="ordersTableDeliverySiteCol">
              <span id="ordersTableDeliverySiteColSpan"
                    @click="setSort('DeliverySite')"
                    style="cursor:pointer">
                    Delivery Site
              </span>
              <span id="ordersTableDeliverySiteColIconSpan" class="ml-1">
                <i id="txtItlOrdersTableDeliverySite"
                   v-if="sortField === 'DeliverySite'"
                   :class="[
                    sortDirection === '-' ? 'fa-caret-down' : 'fa-caret-up',
                    'fa pl-2'
                  ]"
                ></i>
                <i id="txtItlOrdersTableDeliverySiteBlank" v-else class="fas fa-sort opacity-2" ></i>
              </span>
            </b-col>

            <b-col lg="1" md="2" id="ordersTableStatusCol" class="text-center">
              <span @click="setSort('OrderStatus')" style="cursor:pointer" id="ordersTableStatusSpan">
                Status
              </span>
              <span id="ordersTableStatusIconSpan">
                <i id="txtItlOrdersTableStatus"
                  v-if="sortField === 'OrderStatus'"
                  :class="[
                    sortDirection === '-' ? 'fa-caret-down' : 'fa-caret-up',
                    'fa pl-2'
                  ]"
                ></i>

                <i id="txtItlOrdersTableStatusBlank" v-else class="fas fa-sort opacity-2" ></i>
              </span>
            </b-col>
          </b-row>

          <b-row id="rowNoOrders"
            v-if="total === 0"
            class="justify-content-center mt-3 font-weight-bold"
          >
            There are no orders to display. Please search above to return
            results.
          </b-row>

          <ViewOrderTenderCard
            id="viewOrderTenderCard"
            :orders="orders"
            :key="orderTenderKey"
            :showChecks="true"
            :ordersReturned="perPage"
            @changed="selectedChanged"

          />

          <b-row id="rowOrderTendersTablePagination" v-if="total > 0"  class="justify-content-center mt-3" >

            <b-pagination :v-model="orderTenderKey"
                          @change="onPaginationChange"
                          :total-rows="total"
                          :per-page="perPage"
                          @input="getOrderTenders(false)"
                          id="pgnOrderTendersTable"
                          first-number
                          last-number></b-pagination>
          </b-row>
          <b-row id="rowTotalOrders" class="justify-content-center" v-if="total > 0">Displaying Orders {{ orderStart }} to {{ ordersEnd }} of {{ total }}</b-row>
        </b-card>
      </b-row>
    </section>

    <slideout-panel id="orderTendersMainSlideoutPanel"></slideout-panel>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { handleRequestErrorNew } from "@/shared/utils/response-error-handler";
import Loading from "vue-loading-overlay";
import ViewOrderTenderCard from "@/components/order-tender/ViewOrderTenderCard";
import "vue-loading-overlay/dist/vue-loading.css";
import { exportOrderImagesLimit, orderResultsMessageThreshold } from "@/shared/constants";
import { canCreateAdHocOrders } from "@/shared/utils/permissions";
import FilterDropDownMenu from "@/components/order-tender/FilterDropdownMenu";
import { RepositoryFactory } from "@/services/RepositoryFactory";
import { allowBulkImageExports } from "@/shared/feature-flags";

const orderExportRepository = RepositoryFactory.get("orderExportRepository");

export default {
	name: "OrderTenders.vue",

  components: {
    Loading,
    ViewOrderTenderCard,
    FilterDropDownMenu
  },

  data() {
    return {
      isLoading: false,
      expanded: null,
      windowWidth: window.innerWidth,
      errorText: "",
      selectAll: false,
      orderTenderKey: Math.random(),
      sortField: "DELIVERYDATE",
      sortDirection: "-",
      allowBulkImageExports,
      perPage: 20,
      totalRows: 1,
      orderStart: 1,
      ordersEnd: 20,
      pageOptions: [5, 10, 20, 30, 50, 100, 500],
      showTooltip: true,
    }
  },

  async created() {
    this.loadOrders();
  },

  methods: {
    async loadOrders() {
      this.isLoading = true;
      try {
        var requestData = {
          jwtToken: this.$store.state.user.jwtToken,
          searchTerm: this.$store.state.order.searchTerm,
          filters: this.filterObject,
          currentPage: this.$store.state.order.currentPage,
          ordersReturned: this.perPage,
          preferHeader: "return=freight-based",
          sort: this.sortDirection + this.sortField
        };

        await this.$store.dispatch("order/getAllOrders", requestData);
      } catch (error) {
        var errorMessage = handleRequestErrorNew(error);
        this.$snotify.error(errorMessage.detail, "Error");
      }
      this.orderStart = (this.perPage * this.currentPage) - (this.perPage - 1)
      if ((this.perPage * this.currentPage) <= this.total) {
        this.ordersEnd = this.perPage * this.currentPage
      }
      else {
        this.ordersEnd = this.total
      }
      this.isLoading = false;
    },

    async hideTooltipLater() {
      setTimeout(() => {
        this.showTooltip = false;
      }, 30000);
    },


    async getOrderTenders(isSearch) {
      if (isSearch) this.currentPage = 1;

      this.loadOrders();
    },

    clearQuickSearch() {
      this.filterObject.orderReferenceNumber = "";
      this.loadOrders();
    },

    NavigateToNewOrderPage() {
      this.$router.push({ name: "new-order-tender" });
    },

    onPaginationChange(val) {
      this.selectAll = false;
      this.$store.dispatch("order/setPagination", val);
    },

    async GetImagesForSelectedOrders() {
      this.isLoading = true;
      try {
        let showWarning = false;
        if (
          this.$store.state.order.selectedOrders.length > exportOrderImagesLimit
        ) {
          showWarning = true;
        }

				var imagesToGet = this.$store.state.order.selectedOrders.slice(0, exportOrderImagesLimit);
        await this.$store.dispatch("orderImage/getOrderImage", {
          orderNumber: imagesToGet,
          jwtToken: this.$store.state.user.jwtToken,
          processInBackground: true,
					division: this.$store.state.order.orders[0].division
        });

        if (showWarning) {
          this.$snotify.warning(
            `Your request has been received. Only images for the first ${exportOrderImagesLimit} orders will sent. An email will be sent with your requested images.`,
            "Warning"
          );
        } else {
          this.$snotify.success(
            "Your request has been received. An email will be sent with your requested images.",
            "Success",
            {
              timeout: 3000,
              showProgressBar: true
            }
          );
        }
        this.clearAllSelected();
      } catch (e) {
        this.$snotify.error(
          "An error occurred while retrieving image data.",
          "Error"
        );
      } finally {
        this.isLoading = false;
      }
    },

    selectAllOrders(e) {
      let affected = e
        ? this.$store.state.order.orders.filter(w => !w.selected)
        : this.$store.state.order.orders.filter(w => w.selected);

      this.$store.dispatch("order/setOrderSelection", {
        val: e,
        orderNumbers: affected.map(w => w.tmsOrderNumber)
      });

      this.orderTenderKey++;
    },

    clearAllSelected() {
      this.orderTenderKey++;
      this.selectAll = false;
      this.$store.dispatch("order/clearOrderSelection");
    },

    async onImageDownloadButtonClick() {
      try {
        var requestData = {
          jwtToken: this.$store.state.user.jwtToken,
          selectedOrders: this.$store.state.order.selectedOrders
        };

        var responseObject = await orderExportRepository.get(requestData);

        var blob = this.convertByteArrayToPdf(
          responseObject.data.data,
          responseObject.data.contentType
        );

        this.downloadPdf(blob, responseObject.data.fileName);
      } catch (e) {
        this.$snotify.error(
          "There was an error retrieving image data.",
          "Error"
        );
      }
    },

    downloadPdf(blob, fileName) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        window.URL = window.URL || window.webkitURL;
        var link = document.createElement("a");
        var url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        setTimeout(function() {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 100);
      }
    },

    convertByteArrayToPdf(strByteArr, contentType) {
      const binaryString = window.atob(strByteArr);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      return new Blob([bytes], { type: contentType });

    },

		//TODO: Do we need the DATA variable at all?
    selectedChanged() {
      this.selectAll = this.$store.state.order.orders
        .filter(w => w.orderStatus === "CMP")
        .every(w => w.selected);
    },

    showFiltersSlideOut() {
      const panelInstance = this.$showPanel({
        component: FilterDropDownMenu,
				openOn: 'right'
      });

      panelInstance.promise.then(result => {
        if (result) this.loadOrders();
      });
    },

    setSort(sortField) {
      this.sortField = sortField;
      this.sortDirection = this.sortDirection === "-" ? "" : "-";

      this.loadOrders();
    }
  },
  watch: {
    searchTerm: function(val) {
      if (!val) {
        this.getOrderTenders(true);
      }
    }
  },

  computed: {
    ...mapState("order", [
      "orders",
      "total",
      "pageSize",
      "isFilterApplied",
      "filterObject"
    ]),
		...mapState('user', {
      refNumTypesSelected: state => state.refNumTypesSelected
    }),

    currentPage: {
      get() {
        return this.$store.state.order.currentPage;
      },
      set(value) {
        this.$store.dispatch("order/setPagination", value);
      }
    },

    searchTerm: {
      get() {
        return this.$store.state.order.searchTerm;
      },
      set(value) {
        this.$store.dispatch("order/setSearchTerm", value);
      }
    },

    canTenderOrders() {
      return canCreateAdHocOrders(this.$store);
    },

    canCreateAdHocOrders2(){
      return canCreateAdHocOrders(this.$store);
    },

    selectedOrders() {
      return this.$store.state.order.selectedOrders;
    },

    orderResultsMessageThreshold() {
      return orderResultsMessageThreshold;
    },

    filterButtonClass() {
      if (this.isFilterApplied) {
        return "success";
      }
      return "outline-primary";
    },

    filterButtonText() {
      if (this.isFilterApplied) {
        return "View Applied Filters";
      }
      return "Advanced Filters";
    },

		refNumTypesCol1(){
			return this.refNumTypesSelected.find(refNumType => refNumType.colNumber === 1);
		},

		refNumTypesCol2(){
			return this.refNumTypesSelected.find(refNumType => refNumType.colNumber === 2);
		},

		refNumTypesCol3(){
			return this.refNumTypesSelected.find(refNumType => refNumType.colNumber === 3);
    },
    AddOrderScreenPopoverText() {
      return (
        "<span>The Orders list is limited to displaying the last 60 days and next 30 days of orders." +
        "To view orders outside of this limit, click the Advanced Filters button and enter Delivered From and Delivered To dates. " +
        "</span>" +
        "<br /> " +
        "<br /> " +
        "<span>For a detailed overview of advanced searching, including reasons why you can't find your order, please reference " +
        "<a href='https://drive.google.com/file/d/1TGKiZve34mDnp1jtSCmokVWBwfC5aL5T/view?usp=drive_link' target='_blank' rel='noopener'>this help doc.</a></span>"
      );
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
  }
}
</script>

<style scoped>
/* IE10+ */
::-ms-clear {
  display: none;
}

/* Chrome */
::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
}

  .popover {
    border: 2px solid #000 !important;
    max-width: 400px !important;
    font-size: 18px !important;
    font-weight: bold;
  }

</style>
